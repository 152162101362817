.app-navbar {}
.nav-link-with-icon {}


.app-navbar {
  z-index: 1000;
  //border-bottom: 1px solid #eaeaea;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,.1);
}




.app-navbar .bi {
  display: none;
}

.app-navbar .nav-link-with-icon .bi{
  display: inline-block;
  margin-right: 3px;
  //font-size: 16px;
}

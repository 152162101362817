@import "./constants";

//.page-container {
//  min-height: 100vh;
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//  justify-content: center;
//  font-size: calc(10px + 2vmin);
//
//  padding-top: 100px;
//
//}
//
//.page-container.dark-mode {
//  background-color: $color--bg-dark;
//  color: white;
//}
//






.page-container {
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
  font-size: calc(10px + 2vmin);

  //padding-top: 100px;


  background-color: #ffffff;


  //border-radius: 10px;
  //border-color: #9999aa;
  //border-style: solid;
  //border-width: 1px;
  //

  //border: 1px solid #aaaaaa;
}

.page-container.dark-mode {
  background-color: $color--bg-dark;
  color: white;
}




.page-content {
  padding: 10px 20px;
  width: auto;
}




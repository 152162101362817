
.skills-container {
  text-align: left;
  padding: 15px;
}

.skills-container .skill-heading {
  font-size: 20px;
  color: #0dcaf0;
  //display: inline-block;
}

.skills-container .skill-content {
  font-size: 18px;
  color: #ff8000;
  position: relative;
  top: -10px;
  //display: inline-block;
}

.skills-container .skill-content .sub-content {
  position: relative;
  top: 3px;
  font-size: 14px;
  color: #dd7000;
}




.about-page-container {
  //background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  //color: white;
}

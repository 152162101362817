
.three-js-container {
  width: 500px;
  height: 300px;
  outline: none;
  overflow: hidden;
  //background-color: white;
  //border: 1px solid pink;
}


.projects-page-main-content {
  position: relative;
  top: -150px;
  z-index: 0;
}



.page-navbar {
  z-index: 1000;
  //border-bottom: 1px solid #eaeaea;
  //box-shadow: 0 2px 3px 0 rgba(0,0,0,.1);
}

.page-navbar .bi {
  display: none;
}


.page-nav-ul {
  font-size: 16px;
  padding-left: 30px;
}


.navbar-no-bg{
  background-color: transparent !important;

}


.home-page-nav-brand-heading{
  font-size: 48px;
}
